import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import PageLoading from "components/PageLoading";

// import fetch functions
import {
  fetchDevices,
  fetchPorts,
  fetchPatchPanels,
  fetchSwitches,
  fetchNetworkLocations,
  fetchVLAN,
  ports_table,
} from "config/airtable-config";
import FullScreenBox from "components/FullScreenBox";
import { useAuth } from "./AuthContext";

const DataContext = createContext();
export const useData = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [devices, setDevices] = useState([]);
  const [ports, setPorts] = useState([]);
  const [updatedPorts, setUpdatedPorts] = useState([]);
  const [patchPanels, setPatchPanels] = useState([]);
  const [switches, setSwitches] = useState([]);
  const [networkLocations, setnetworkLocations] = useState([]);
  const [vlan, setVLAN] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          devicesData,
          portsData,
          patchPanelsData,
          switchesData,
          networkLocationsData,
          vlanData,
        ] = await Promise.all([
          fetchDevices(),
          fetchPorts(),
          fetchPatchPanels(),
          fetchSwitches(),
          fetchNetworkLocations(),
          fetchVLAN(),
        ]);

        setDevices(devicesData);
        setPorts(portsData);
        setPatchPanels(patchPanelsData);
        setSwitches(switchesData);
        setnetworkLocations(networkLocationsData);
        setVLAN(vlanData);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const prepareUpdatesForAirtable = useCallback(
    (updatedPorts) => {
      return updatedPorts.map((port) => ({
        id: port.id,
        fields: {
          "Connected Port":
            port["Connected Port"].length > 0 ? port["Connected Port"] : null,
          last_updated_by: [user.id],
        },
      }));
    },
    [user]
  );

  const updateAirtableRecords = useCallback(async (updates) => {
    try {
      await ports_table.update(updates);
    } catch (error) {
      console.error("Error updating Airtable records: ", error);
    }
  }, []);

  useEffect(() => {
    if (updatedPorts.length > 0) {
      const updates = prepareUpdatesForAirtable(updatedPorts);
      updateAirtableRecords(updates);
      setUpdatedPorts([]);
    }
  }, [updatedPorts, prepareUpdatesForAirtable, updateAirtableRecords]);

  const updatePortsConnection = useCallback(
    (sourcePortID, targetPortID) => {
      setPorts((prevPorts) => {
        let newUpdatedPorts = []; // Track ports that need their records updated in Airtable

        const newPorts = prevPorts.map((port) => {
          const connectedPortArray = port["Connected Port"] || [];
          const isConnectedToSource = connectedPortArray.includes(sourcePortID);
          const isConnectedToTarget = connectedPortArray.includes(targetPortID);

          // If targetPortID is empty, clear any existing connection from sourcePortID and from any port connected to it
          if (!targetPortID) {
            if (port.id === sourcePortID || isConnectedToSource) {
              newUpdatedPorts.push({ id: port.id, "Connected Port": [] });
              return {
                ...port,
                "Connected Port": [],
                last_updated_by: [user.id],
                last_updated: new Date().toISOString(),
              };
            }
          }

          // Clear previous connections of targetPortID if it's being re-assigned
          if (targetPortID && isConnectedToTarget && port.id !== sourcePortID) {
            newUpdatedPorts.push({ id: port.id, "Connected Port": [] });
            return {
              ...port,
              "Connected Port": [],
              last_updated_by: [user.id],
              last_updated: new Date().toISOString(),
            };
          }

          // Set new connection for sourcePortID and targetPortID
          if (port.id === sourcePortID && targetPortID) {
            if (!connectedPortArray.includes(targetPortID)) {
              newUpdatedPorts.push({
                id: sourcePortID,
                "Connected Port": [targetPortID],
              });
              return {
                ...port,
                "Connected Port": [targetPortID],
                last_updated_by: [user.id],
                last_updated: new Date().toISOString(),
              };
            }
          } else if (port.id === targetPortID && targetPortID) {
            if (!connectedPortArray.includes(sourcePortID)) {
              newUpdatedPorts.push({
                id: targetPortID,
                "Connected Port": [sourcePortID],
              });
              return {
                ...port,
                "Connected Port": [sourcePortID],
                last_updated_by: [user.id],
                last_updated: new Date().toISOString(),
              };
            }
          }

          return port;
        });

        // Update Airtable if there are changes
        if (newUpdatedPorts.length > 0) {
          setUpdatedPorts((current) => [...current, ...newUpdatedPorts]);
        }

        return newPorts;
      });
    },
    [user]
  );

  const value = {
    devices,
    setDevices,
    ports,
    setPorts,
    updatePortsConnection,
    patchPanels,
    setPatchPanels,
    switches,
    setSwitches,
    networkLocations,
    setnetworkLocations,
    vlan,
    setVLAN,
  };

  if (loading)
    return (
      <FullScreenBox>
        <PageLoading />
      </FullScreenBox>
    );
  if (error) return <div>Error: {error.message}</div>;
  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};
