// src/AuthContext.js
import FullScreenBox from "components/FullScreenBox";
import PageLoading from "components/PageLoading";
import { fetchAllUsers, updateUser } from "config/airtable-config";
import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);

  const login = useCallback((user) => {
    setIsAuthenticated(true);
    setUser(user);
    localStorage.setItem("user", JSON.stringify(user));
    // Update last logged in time upon login
    updateLastLoggedInTime(user.id);
  }, []);

  const logout = useCallback(() => {
    setIsAuthenticated(false);
    setUser({});
    localStorage.removeItem("user");
  }, []);

  const updateLastLoggedInTime = async (userID) => {
    try {
      await updateUser(userID, {
        last_logged_in: new Date().toISOString(),
      });
    } catch (error) {
      console.error("Error updating user last logged in time: ", error);
    }
  };

  const authenticate = useCallback(
    (username) => {
      const foundUser = allUsers.find(
        (user) => user.username === username || user.email_address === username
      );
      if (foundUser) {
        login(foundUser);
      } else {
        throw new Error("Couldn't find your account");
      }
    },
    [allUsers, login]
  );

  useEffect(() => {
    const loadUsers = async () => {
      setLoading(true);
      try {
        const users = await fetchAllUsers();
        setAllUsers(users);
        const storedUser = localStorage.getItem("user");
        if (storedUser) {
          const parsedUser = JSON.parse(storedUser);
          const validUser = users.find((u) => u.id === parsedUser.id);
          if (validUser) {
            setUser(validUser);
            setIsAuthenticated(true);
            // Update last logged in time when user is found in local storage
            updateLastLoggedInTime(validUser.id);
          } else {
            logout();
          }
        }
      } catch (error) {
        console.error("Error fetching users: ", error);
      } finally {
        setLoading(false);
      }
    };

    loadUsers();
  }, [logout]);

  const value = {
    isAuthenticated,
    user,
    allUsers,
    authenticate,
    login,
    logout,
  };

  if (loading) {
    return (
      <FullScreenBox>
        <PageLoading />
      </FullScreenBox>
    );
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
