import React from "react";
import { Box, Typography } from "@mui/material";

const PageLoading = () => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        boxSizing: "border-box",
        padding: 3,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="lds-dual-ring"></div>
      <Typography variant="h5">Page loading</Typography>
    </Box>
  );
};

export default PageLoading;
