import { useNavigate, useParams } from "react-router-dom";

// Import custom contexts
import { useData } from "contexts/DataContext";

// Import MUI components
import { Box, Typography, Button } from "@mui/material";
import grey from "@mui/material/colors/grey";
import lightBlue from "@mui/material/colors/lightBlue";

const LeftSidebar = () => {
  const navigate = useNavigate();
  const { networkLocationID } = useParams();
  const buildingPhases = ["Phase 1", "Phase 2", "Phase 3", "Phase 4"];
  const { networkLocations } = useData();

  return (
    <Box
      id="sidebar"
      sx={{
        borderRight: `2px solid ${grey[400]}`,
        boxSizing: "border-box",
        width: `clamp(200px, 15%, 300px)`, // Responsive width
        height: "100%",
        overflowY: "auto", // Enable vertical scrolling
        "&::-webkit-scrollbar": {
          display: "none", // Hide scrollbar for WebKit browsers
        },
        scrollbarWidth: "none", // Hide scrollbar for Firefox
        msOverflowStyle: "none", // Hide scrollbar for IE 10+
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
      }}
    >
      {buildingPhases.map((phase, index) => (
        <div key={index}>
          <Box
            sx={{
              backgroundColor: grey[400],
              padding: 1,
              //   display: 'flex',
            }}
          >
            <Typography variant="h6">{phase}</Typography>
          </Box>
          {networkLocations
            .filter((location) => location["Building Location"].includes(phase))
            .map((location) => (
              <Button
                key={location.id}
                variant="text"
                fullWidth
                sx={{
                  borderRadius: 0,
                  textAlign: "left",
                  justifyContent: "flex-start",
                  color: "black",
                  backgroundColor:
                    location.id === networkLocationID
                      ? lightBlue[100]
                      : "transparent",
                }}
                onClick={() => navigate(`/location/${location.id}`)}
              >
                {location.Name}
              </Button>
            ))}
        </div>
      ))}
      <Box
        mt={2}
        p={2}
        sx={{
          borderTop: `2px solid ${grey[400]}`,
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="body2" color="textSecondary" textAlign="center">
          Upcoming Feature under development
        </Typography>
      </Box>
    </Box>
  );
};

export default LeftSidebar;
