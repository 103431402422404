import Airtable from "airtable";

// Configure Airtable
Airtable.configure({
  endpointUrl: "https://api.airtable.com",
  apiKey: process.env.REACT_APP_AIRTABLE_API_KEY,
});

const base = Airtable.base(process.env.REACT_APP_AIRTABLE_BASE_ID);
export default base;

export const users_table = base("Users");
export const devices_table = base("Edge Devices");
export const ports_table = base("Ports");
export const patch_panel_table = base("Patch Panel");
export const switches_table = base("Switches");
export const network_locations_table = base("Network Closet/Location");
export const vlan_table = base("VLAN");

// Fetch functions

export const fetchDevices = async () => {
  try {
    const records = await devices_table.select().all();
    return records.map((record) => ({
      id: record.id,
      ...record.fields,
    }));
  } catch (error) {
    console.error("Error fetching devices: ", error);
    throw error;
  }
};

export const fetchPorts = async () => {
  try {
    const records = await ports_table.select().all();
    return records.map((record) => ({
      id: record.id,
      ...record.fields,
    }));
  } catch (error) {
    console.error("Error fetching ports: ", error);
    throw error;
  }
};

export const fetchPatchPanels = async () => {
  try {
    const records = await patch_panel_table.select().all();
    return records.map((record) => ({
      id: record.id,
      ...record.fields,
    }));
  } catch (error) {
    console.error("Error fetching patch panels: ", error);
    throw error;
  }
};

export const fetchSwitches = async () => {
  try {
    const records = await switches_table.select().all();
    return records.map((record) => ({
      id: record.id,
      ...record.fields,
    }));
  } catch (error) {
    console.error("Error fetching switches: ", error);
    throw error;
  }
};

export const fetchNetworkLocations = async () => {
  try {
    const records = await network_locations_table.select().all();
    return records.map((record) => ({
      id: record.id,
      ...record.fields,
    }));
  } catch (error) {
    console.error("Error fetching network locations: ", error);
    throw error;
  }
};

export const fetchVLAN = async () => {
  try {
    const records = await vlan_table.select().all();
    return records.map((record) => ({
      id: record.id,
      ...record.fields,
    }));
  } catch (error) {
    console.error("Error fetching VLAN: ", error);
    throw error;
  }
};

export const fetchAllUsers = async () => {
  try {
    const records = await users_table.select().all();
    return records.map((record) => ({
      id: record.id,
      ...record.fields,
    }));
  } catch (error) {
    console.error("Error fetching users: ", error);
    throw error;
  }
};

export const findUserByID = async (id) => {
  try {
    const record = await users_table.find(id);
    return {
      id: record.id,
      ...record.fields,
    };
  } catch (error) {
    console.error("Error fetching user: ", error);
    throw error;
  }
};

export const findUserByEmail = async (email) => {
  try {
    const records = await users_table
      .select({ filterByFormula: `email_address = "${email}"` })
      .all();
    return records.map((record) => ({
      id: record.id,
      ...record.fields,
    }))[0];
  } catch (error) {
    console.error("Error fetching user: ", error);
    throw error;
  }
};

export const updatePortTerminationLocation = async (
  portID,
  location,
  userID
) => {
  try {
    await ports_table.update(portID, {
      "Termination Location": location,
      last_updated_by: [userID],
    });
  } catch (error) {
    console.error("Error updating port: ", error);
    throw error;
  }
};

export const updateUser = async (userID, user) => {
  try {
    await users_table.update(userID, user);
  } catch (error) {
    console.error("Error updating user: ", error);
    throw error;
  }
};
