import React from "react";
import { Outlet } from "react-router-dom";
import { Box, AppBar, Typography, Toolbar, Button } from "@mui/material";
import LeftSidebar from "components/LeftSidebar";
import { useAuth } from "contexts/AuthContext";

const MainLayout = () => {
  const { user, logout } = useAuth();

  const handleLogout = () => {
    logout();
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Network Mapping Documentation
          </Typography>

          {/* <Button color="inherit">Login</Button> */}
          {user && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="body1"
                component="div"
                sx={{ marginRight: 2 }}
              >
                Welcome, {user.first_name}
              </Typography>
              <Button color="inherit" variant="outlined" onClick={handleLogout}>
                Logout
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      <Box
        id="layout-body"
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          flexGrow: 1,
          height: "100%",
          boxSizing: "border-box",
        }}
      >
        <LeftSidebar />
        <Box
          sx={{
            flexGrow: 1,
            // border: "1px solid red",
            boxSizing: "border-box",
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
