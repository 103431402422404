import { Suspense, lazy } from "react";
import MainLayout from "layouts/MainLayout";
import { Routes, Route } from "react-router-dom";

// Import custom components
import FullScreenBox from "components/FullScreenBox";
import PageLoading from "components/PageLoading";
import ProtectedRoutes from "routing/ProtectedRoutes";

// Lazy import pages
const HomePage = lazy(() => import("pages/HomePage"));
const LoginPage = lazy(() => import("pages/LoginPage"));
const NetworkLocation = lazy(() => import("pages/NetworkLocation"));

function App() {
  return (
    <Suspense
      fallback={
        <FullScreenBox>
          <PageLoading />
        </FullScreenBox>
      }
    >
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route element={<ProtectedRoutes />}>
            <Route index element={<HomePage />} />
            <Route
              path="location/:networkLocationID"
              element={<NetworkLocation />}
            />
          </Route>
        </Route>
        <Route path="/login" element={<LoginPage />} />
      </Routes>
    </Suspense>
  );
}

export default App;
